import {defineStore} from 'pinia';
import axios from "axios";

let intervalTimerList = [];


export const viewData = defineStore('viewData', {
    state: () => {
        return {
            isLogin: false,
            isMobile: true,
            showSubMenu: true,
            currentUser: {},
            applicationUser: {},
            applicationUserList:[],
            pageAreaName: '',
            pageTitle: '',
            mainMenuName: '',
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            selectFieldList: [],
            dataFieldList: [],
            // TODO 清理冗餘的程式碼
            ssoUrl: '',
            ssoReady: false,
            customer_id:'',
            employee_id:'',
            account_id:'',
            organizationsList:[],
            currentOrganizationId:'',
            organizationData:{},
            organizationFieldList:[],
            co2List:[],
            gwpList:[],
            categoryList: [{value:1,label:'類別1 直接排放源'},{value:2,label:'類別2 能源間接排放源'},{value:3,label:'類別3 運輸間接排放源'},{value:4,label:'類別4 組織使用產品產生之間接排放源'},{value:5,label:'類別5 使用組識產品產生之間接排放源'},{value:6,label:'類別6 其它間接排放源'}],
            subCategoryList:[
                {categoryId:1,value:1.1,label:'固定排放'},{categoryId:1,value:1.2,label:'移動排放'},{categoryId:1,value:1.3,label:'製程排放'},{categoryId:1,value:1.4,label:'逸散排放'},
                {categoryId:2,value:2.1,label:'外購電力'},{categoryId:2,value:2.2,label:'外購能源'},
                {categoryId:3,value:3.1,label:'上游運輸及貨物配送'},{categoryId:3,value:3.2,label:'下游運輸及貨物配送'},{categoryId:3,value:3.3,label:'員工通勤'},{categoryId:3,value:3.4,label:'客戶及訪客運輸'},{categoryId:3,value:3.5,label:'商務旅行'},
                {categoryId:4,value:4.1,label:'購買商品的排放量(能源/原物料)'},{categoryId:4,value:4.2,label:'資本財/資本貨物的排放量'},{categoryId:4,value:4.3,label:'處理固體和液體廢棄物產生的排放量'},{categoryId:4,value:4.4,label:'資產使用產生的排放量'},{categoryId:4,value:4.5,label:'未於上述服務使用產生的排放量'},
                {categoryId:5,value:5.1,label:'來自組織的產品(銷售/使用階段)'},{categoryId:5,value:5.2,label:'來自組織的產品(出租使用)'},{categoryId:5,value:5.3,label:'來自組織的產品(廢棄階段)'},{categoryId:5,value:5.4,label:'加盟/各項投資(投資標的之排放)'},
                {categoryId:6,value:6.1,label:'項目說明'},
            ],
        }
    },
    getters: {
        targetInfo(){
            return this.organizationData
        },
    },
    actions: {
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            try{
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            }catch(err){
                console.log(err);
            }
            const mobileWidthThreshold = 768;
            if (this.windowWidth <= mobileWidthThreshold) {
                this.showSubMenu = false;
                this.isMobile = true
            }else{
                this.showSubMenu = true;
                this.isMobile = false
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(() => {
                this.drawerName = drawerName
            }, 330)
        },
        clearPageInterval() {
            intervalTimerList.forEach(i => {
                clearInterval(i);
            });
            intervalTimerList = [];
        },
        async beforePageChange(to, from) {
            this.clearPageInterval()
            console.log('beforePageChange', to, from)
        },
        afterPageChange() {
            let path = location.pathname
            let pageName = path.split('/').join('_').substring(1)
            if (pageName.length === 1) pageName = 'components_' + pageName
            this.pageAreaName = pageName
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0]
            }, 30)
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        ssoCheck(){
            let v = this
            return new Promise((resolve, reject) =>{
                let p = location.hostname
                if (p === 'localhost' || p === '127.0.0.1'){
                    p = `${location.protocol}//${location.hostname}:${location.port}`
                    if (p.endsWith(':')) p = p.substring(0, p.length - 1)
                }else{
                    p = p.replace('.greenshepherd.com.tw', '')
                }

                axios.get(`/api/sso/check?service=${encodeURI(p)}`).then(res => {
                    console.log(res.data)
                    v.isLogin = res.data.isLogin
                    v.ssoUrl = res.data.url
                    v.currentUser = res.data.data
                    if (v.ssoUrl !== ''){
                        v.ssoReady = true
                    }
                    v.customer_id=res.data.data.customer_id === undefined ? '0' : res.data.data.customer_id
                    v.employee_id=res.data.data.employee_id
                    v.account_id=res.data.data.user===undefined? 0:res.data.data.user
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        getApplicationData(){
            console.log(this.currentUser)
            let url='/api/sso/app_user_data?where={'
            if(this.account_id!==0){
                url+=`"account":"${this.account_id}"`
            }
            url+="}"
            axios.get(url).then(res =>{
                this.applicationUser=res.data.data[0]
                console.log(this.applicationUser)
            })
        },
        async doSelect(f){
            console.log(f)
            this.currentOrganizationId=f.organization_id
            await this.loadOrganizationData()
        },
        loadFieldData(){
            return new Promise((resolve, reject) => {
                console.log(this.customer_id)
                console.log(this.employee_id)
                console.log(this.account_id)
                let url = ''

                url = '/api/field/current?size=99999&'
                url += `where={"user_account":"${this.account_id}"}`
                // url='/api/field/list?size=99999'
                axios.get(url).then((res2) => {
                    let d = res2.data.data
                    if (Array.isArray(d)) this.dataFieldList = d
                    this.dataFieldList.forEach(x => {
                        x.city = x.field_address.substr(0, 3)
                        x.name = x.field_name
                        x.id = x.field_id
                        x.kind = x.field_kind
                        x.address = x.field_address
                        x.lat_lng = x.field_lat_lng
                    })
                    console.log(this.dataFieldList)
                    let dataField = this.dataField
                    // console.log(dataField)
                    this.selectFieldList = dataField.filter(x => x.id)
                    resolve()
                }).catch(err => reject(err))
            })

        },
        loadApplicationUser(){
          let url="/api/user/application_user"
          axios.get(url).then(res=>{
            this.applicationUserList=res.data
              console.log(this.applicationUserList)
          })
        },
        loadCanAccessOrganizationData(){
            console.log('loadCanAccessOrganizationData')
            let url='/api/committee/get_organization?account_id=' + this.account_id
            console.log(this.account_id)
            axios.get(url).then(res =>{
                console.log(res)
                this.currentOrganizationId=res.data[0].organization_id
                console.log(this.currentOrganizationId)
                this.organizationsList=res.data
                console.log(this.organizationsList)
                this.loadOrganizationData()
            })

        },
        async loadOrganizationData(){
            let url="/api/organization/get_data?id="+this.currentOrganizationId
            this.loadOrganizationFieldData()
            await axios.get(url).then(res=>{
                if(res.data.error===undefined){
                    this.organizationData=res.data[0]
                    this.organizationData.owner_id=parseInt(this.organizationData.owner)
                    console.log(this.organizationData)
                    }
            })
        },
        loadOrganizationFieldData(){
            let url="/api/esg_field/get_data?organization_id="+this.currentOrganizationId
            console.log(url)
            axios.get(url).then(res=>{
                console.log(res.data)
                if(res.data.error===undefined){
                    this.organizationFieldList=res.data
                    console.log(this.organizationFieldList)
                    }
                })
        },
        loadCo2ListData(){
            axios.get('/api/co2/get_data').then(res=>{
                console.log(res.data)
              this.co2List=res.data.data
            })
            this.loadGWPListData()
        },
        loadGWPListData(){
            axios.get('/api/co2/get_gwp').then(res=>{
                console.log(res.data)
                let list=res.data.data
                console.log(res.data)
                let list1=[]
                for(let i=0;i<list.length;i++){
                if((list1.filter(x=>x.GWP_id===list[i].GWP_id)).length>0)
                  continue
                list1.push(list[i])
                }
                this.gwpList=list1
            })
        }
    },
})

export default viewData