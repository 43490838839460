export {pageData};

let pageData = {
    'login':[
        {
            title: '登入',
            name: 'user',
            items: [
                {dir: 'user', title: '登入頁面', page: 'Login'},
            ]
        }
    ],
    'nav': [
        {
            title: '摘要',
            flow_title: '基本設定',
            name: 'overview',
            items: [
                {dir: 'overview', title: '碳盤查看板', page: 'Dashboard'},
                {dir: 'overview', title: '減碳藍圖', page: 'Blueprint'},

                {dir: 'overview', title: '組織基本資料', page: 'BasicInformation', inflow: true},
                {dir: 'overview', title: '案場位置設定', page: 'PlaceSetting', inflow: true},
            ]
        },
        {
            title: '專案',
            flow_title: '專案設定',
            name: 'setting',
            items: [
                {dir: 'setting', title: '盤查專案管理', page: 'ProjectManage'},
                {dir: 'setting', title: '報告邊界設定', page: 'ReportingBoundary', inflow: true},
                {dir: 'setting', title: '地理邊界設定', page: 'EntityBoundarySetting', inflow: true},
                {dir: 'setting', title: '排放源管理', page: 'EmissionSourceManagement', inflow: true},
                {dir: 'setting', title: '數據資料管理', page: 'DataQualityManagement', inflow: true},

                {dir: 'setting', title: '碳排放係數管理', page: 'CarbonEmissionCoefficient'},
                {dir: 'setting', title: '碳足跡係數管理', page: 'CarbonFootprintCoefficient'},
            ]
        },
        {
            title: '盤查',
            flow_title: '碳排計算',
            name: 'calculation',
            items: [
                {dir: 'calculation', title: '碳盤查計算', page: 'CarbonInventoryCalculation', inflow: true},
                {dir: 'calculation', title: '排放源計算(手動)', page: 'EmissionSourceCalculation', inflow: true},
                {dir: 'calculation', title: '數據儀表板(自動)', page: 'DataDashboard', inflow: true},
                {dir: 'calculation', title: '不確定性分析', page: 'UncertaintyAnalysis', inflow: true},
                {dir: 'calculation', title: '逸散率管理', page: 'RefrigerantEmissionFactor', inflow: true},
            ]
        },
        {
            title: '報告',
            flow_title: '盤查報告',
            name: 'report',
            items: [
                {dir: 'report', title: '氣體排放統計表', page: 'GasEmissionStatistical', inflow: true},
                {dir: 'report', title: '氣體排放明細表', page: 'GasEmissionDetail', inflow: true},
                {dir: 'report', title: '盤查清冊', page: 'InventoryList', inflow: true},
                {dir: 'report', title: 'ESG減碳成效報告書', page: 'ESGReport', inflow: true},
                {dir: 'report', title: '跨專案盤查', page: 'CrossProjectInventory'},
            ]
        },
        // {
        //     title: '雙肩',
        //     name: 'test',
        //     items: [
        //         {dir: 'test', title: '碳盤查計算', page: 'CarbonEmissionsCalculation'},
        //         {dir: 'test', title: '氣體排放統計表', page: 'GasEmissionStatistical'},
        //         {dir: 'test', title: '數據儀錶板', page: 'DashBoard'},
        //         {dir: 'test', title: '排放源計算', page: 'EmissionSourceCalculation'},
        //         {dir: 'test', title: '廠區或公司項目', page: 'ProjectManage'},
        //     ]
        // }
    ],
    'solution': [
        {dir: 'solution', title: '晶質變壓器', page: 'TransformerSwitch', image: '/static/img/overview/solution.png', desc: '說明...說明...說明...說明'},
        {dir: 'solution', title: '電力資料智慧化', page: 'GWPSearch', image: '/static/img/overview/solution.png', desc: '說明...說明...說明...說明'},
    ],
    'page':[
        {dir: 'user', title: '登入頁面', page: 'Login'},
    ],
    'test':[
        {dir: 'test', title: '碳排管理', page: 'CarbonEmissionCoefficientManage', inflow: true},
        {dir: 'test', title: '碳足跡管理', page: 'CarbonFootprintCoefficientManagement', inflow: true},
        {dir: 'test', title: '減碳藍圖', page: 'CarbonReductionBlueprint', inflow: true},
        {dir: 'test', title: '減碳成效報告書', page: 'CarbonReport', inflow: true},
        {dir: 'test', title: '成效總計', page: 'CoefficientSummaryTable'},
        {dir: 'test', title: '碳排列表', page: 'GasEmissionList'},
        {dir: 'test', title: '碳排清單', page: 'GasEmissionStatisticsForms'},
        {dir: 'test', title: '地理邊界', page: 'GeographicBoundary'},
        {dir: 'test', title: '報告邊界', page: 'ReportBoundary'},
    ],
}
